"use client";

/**
 * Third-party libraries.
 */
import { QuestionCircleOutlined as QuestionCircleOutlinedIcon } from "@ant-design/icons";
import { Button, Select, Switch, Tooltip } from "antd";
import Modal from "antd/es/modal/Modal";
import { useMemo } from "react";

/**
 * Project components.
 */
import { useApplicationContext } from "@/components/client/context";
import { Icon } from "@/components/client/icon";
import { BusinessHoursForm } from "@/components/client/settings/business-hours-form";
import { useSystemPreferenceContext } from "@/components/client/system-preference";
import {
  BusinessStatus,
  CallRecording,
  SessionInitiationProtocol,
} from "@/components/common/system-preference/types";
import { DevelopmentOnly } from "../development";

/**
 * Properties of the settings modal.
 */
export type SettingsModalProps = {};

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
export function SettingsModal(props: SettingsModalProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { mode, notification, setMode, setShowSettings, showSettings } =
    useApplicationContext();

  const {
    businessStatus,
    businessStatusLoading,
    sessionInitationProtocol,
    sessionInitationProtocolLoading,
    callRecordings,
    callRecordingsLoading,
    updateBusinessStatus,
    updateSessionInitiationProtocol,
    updateCallRecordings,
  } = useSystemPreferenceContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates whether the Session Initiation Protocol is enabled.
   */
  const sessionInitiationProtocolEnabled = useMemo(
    () => sessionInitationProtocol === "enabled",
    [sessionInitationProtocol],
  );

  /**
   * Indicates whether the Call Recordings is enabled.
   */
  const callRecordingsEnabled = useMemo(
    () => callRecordings === "enabled",
    [callRecordings],
  );

  /**
   * The phone number used by the system to make outbound calls.
   *
   * This same phone number is also used by clients to call the system.
   *
   * If Session Initiation Protocol is enabled, this will be the SIP phone number.
   * Otherwise, this will be the Twilio phone number.
   */
  const phoneNumber = sessionInitiationProtocolEnabled
    ? process.env.NEXT_PUBLIC_TWILIO_SESSION_INITIATION_PROTOCOL_PHONE_NUMBER
    : process.env.NEXT_PUBLIC_TWILIO_PHONE_NUMBER;

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <Modal
      title="Settings"
      open={showSettings}
      onCancel={() => setShowSettings(false)}
      onOk={() => setShowSettings(false)}
      onClose={() => setShowSettings(false)}
    >
      <div className="grid w-full grid-cols-2 gap-6 p-8">
        <DevelopmentOnly>
          <div className="col-span-1">
            <div className="flex flex-nowrap gap-2 text-nowrap">
              <span>Development Mode</span>
              <Tooltip title="Live: Uses live APIs for component values. Mock: Uses static component values.">
                <QuestionCircleOutlinedIcon className="cursor-pointer" />
              </Tooltip>
            </div>
          </div>
          <div className="col-span-1">
            <Switch
              checkedChildren="True"
              unCheckedChildren="False"
              onChange={(checked) => {
                setMode(checked ? "development" : undefined);
              }}
              value={mode === "development"}
            />
          </div>
        </DevelopmentOnly>
        <div className="col-span-1 flex items-center">
          <div className="flex flex-nowrap gap-2 text-nowrap">
            <span>Phone Number</span>
            <Tooltip title="This is the phone number used to reach this application.">
              <QuestionCircleOutlinedIcon className="cursor-pointer" />
            </Tooltip>
          </div>
        </div>
        <div className="col-span-1 flex items-center gap-2">
          <span>{phoneNumber}</span>
          <Tooltip title="Copy phone number.">
            <Button
              icon={<Icon className="!text-tpl-navy" src="copy" />}
              onClick={async () => {
                await navigator.clipboard.writeText(phoneNumber);

                notification.info({
                  duration: 3,
                  message: `Copied ${phoneNumber} to clipboard.`,
                  showProgress: true,
                });
              }}
              shape="circle"
              type="text"
            />
          </Tooltip>
        </div>
        <div className="col-span-1">
          <div className="flex flex-nowrap gap-2 text-nowrap">
            <span>Business Status</span>
            <Tooltip title="Open: Accept call from clients. Close: Do not accept call from clients. Affects the whole system. Schedule: Base the open/close status on the schedule for the day.">
              <QuestionCircleOutlinedIcon className="cursor-pointer" />
            </Tooltip>
          </div>
        </div>
        <div className="col-span-1">
          <Select
            className="w-full"
            disabled={businessStatusLoading}
            loading={businessStatusLoading}
            onChange={(value, option) => {
              updateBusinessStatus({
                businessStatus: value,
              });
            }}
            value={businessStatus}
          >
            {Object.entries(BusinessStatus).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </div>

        {businessStatus === BusinessStatus.SCHEDULE && (
          <div className="col-span-2">
            <BusinessHoursForm />
          </div>
        )}

        <div className="col-span-1">
          <div className="flex flex-nowrap gap-2 text-nowrap">
            <span>Session Initiation Protocol</span>
            <Tooltip title="Use a specific phone number for outbound calls. Enabled: SG phone number. Disabled: US phone number.">
              <QuestionCircleOutlinedIcon className="cursor-pointer" />
            </Tooltip>
          </div>
        </div>
        <div className="col-span-1">
          <Switch
            checkedChildren="Enabled"
            disabled={mode !== "development"}
            loading={sessionInitationProtocolLoading}
            unCheckedChildren="Disabled"
            onChange={(checked) => {
              updateSessionInitiationProtocol({
                sessionInitationProtocol: (checked
                  ? "enabled"
                  : "disabled") as SessionInitiationProtocol,
              });
            }}
            value={sessionInitiationProtocolEnabled}
          />
        </div>

        <div className="col-span-1">
          <div className="flex flex-nowrap gap-2 text-nowrap">
            <span>Call Recordings</span>
            <Tooltip title="Inbound and Outbound calls will be recorded upon connecting.">
              <QuestionCircleOutlinedIcon className="cursor-pointer" />
            </Tooltip>
          </div>
        </div>
        <div className="col-span-1">
          <Switch
            checkedChildren="Enabled"
            disabled={mode !== "development"}
            loading={callRecordingsLoading}
            unCheckedChildren="Disabled"
            onChange={(checked) => {
              updateCallRecordings({
                callRecordings: (checked
                  ? "enabled"
                  : "disabled") as CallRecording,
              });
            }}
            value={callRecordingsEnabled}
          />
        </div>
      </div>
    </Modal>
  );
}
